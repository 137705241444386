import { Router, Route, Set } from '@redwoodjs/router'

import { Authenticated } from './auth'

const Routes = () => {
  return (
    <Router>
      <Route path="/xero-signup" page={XeroSignUpPage} name="xeroSignUp" />
      <Route path="/xero-signup/consent" page={XeroSignupConsentPage} name="xeroSignupConsent" />
      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/sign-up" page={SignUpPage} name="signUp" />
      <Route notfound page={NotFoundPage} />
      <Route path="/upload/{uploadUrl:String}" page={ClientUploadPage} name="clientUpload" />

      <Set wrap={Authenticated} unauthenticatedRoute="login">
        <Route path="/" page={HomePage} name="home" />
        <Route path="/client/{id:String}" page={ClientPage} name="client" />
        <Route path="/client/{id:String}/settings" page={ClientSettingsPage} name="clientSettings" />
        <Route path="/client/{id:String}/archive" page={ArchiveInboxPage} name="archiveInbox" />
        <Route path="/client/{clientId:String}/archive/{archiveItemId:String}" page={ArchivedItemDetailsPage} name="archivedItemDetails" />
        <Route path="/client/{clientId:String}/transaction/{transactionId:String}" page={TransactionDetailsPage} name="transactionDetails" />
        <Route path="/xero-callback" page={XeroCallbackPage} name="xeroCallback" />
        <Route path="/xero-after-subscribe" page={XeroAfterSubscribePage} name="xeroAfterSubscribe" />

        <Route path="/quickbooks-callback" page={QuickBooksCallbackPage} name="quickBooksCallback" />
      </Set>
    </Router>
  )
}

export default Routes
